.set-page {
  .main {
    padding: 20px;

    .obsolete {
      background: orange;
      font-size: 1.5em;
      padding: 3px;
    }

    .title {
      text-align: left;
      font-weight: bold;
    }

    .card-list {
      margin-bottom: 20px;
      table {
        th {
          text-align: left;
        }
        td {
          text-align: left;
          padding: 5px;
        }
      }
      .warning {
        color: red;
        padding: 10px;
      }
    }

    .set-settings {
      input,select {
        width: 100%;
      }
    }

    .assets-settings {
      .header {
        .right {
          text-align: right;
        }
      }
      table {
        td {
          text-align: left;
          padding: 5px;
          overflow-wrap: anywhere;
        }
      }
      li {
        text-align: left;
        .warning {
          color: red;
          margin-left: 5px;
        }
      }
      .dropzone {
        border: solid 1px;
        padding: 20px;
        cursor: pointer;
      }

      .title {
        font-weight: bold;
      }

      .warning {
        color: red;
        font-weight: bold;
      }
    }

    .theme-settings {
      table {
        width: 100%;
      }
      .dropzone {
        border: solid 1px;
        padding: 20px;
        cursor: pointer;

        .title {
          font-weight: bold;
          text-align: center;
        }
        .format {
          margin-top: 10px;
        }
      }
    }
  }
}
