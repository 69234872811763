.card-page {
  .main {
    padding: 20px;

    >.title {
      text-align: left;
    }

    table {
      border: solid 1px;
      width: 100%;
      margin-bottom: 20px;

      td {
        padding: 10px;
      }

      input,select {
        width: 100%;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      .col {
      }
    }

    .dropzone {
      border: solid 1px;
      padding: 20px;
      cursor: pointer;

      .title {
        font-weight: bold;
      }
      .format {
        margin-top: 10px;
      }
    }

    .addressable-selection {
      margin: 5px;
      font-size: 0.8em;
      text-align: left;
    }
    .addressable-key-option {
      margin: 0 5px;
      cursor: pointer;
      text-decoration: underline;
    }

    .text-and-button {
      display: flex;
      button {
        width: 100px;
      }
    }
  }
}
