body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table.common-table {
  margin-top: 10px;
  border: solid 1px;
  width: 100%;
  border-spacing: 0;
  font-size: 12px;
  text-align: center;
  thead {
    background: #3f4652;
    color: white;
    tr {
      th {
        padding: 5px;
        &.shrink {
          width: 1%;
          white-space: nowrap
        }
      }
    }
  }
  tbody {
    tr {
      border: solid 1px;
      td {
        padding: 5px;
        &.shrink {
          width: 1%;
          white-space: nowrap;
        }
      }
      &.selectable {
        &:hover {
          background: #ddd;
        }
      }
      .selectable {
        cursor: pointer;
      }

    }
  }
}

.common-header {
  display: flex;
  flex-direction: row;
  background: #3f4652;
  color: white;
  padding: 10px;

  .link {
    cursor: pointer;
  }
  .left {
    flex: 1;
    text-align: left;
  }
  .right {
    text-align: right;
  }
}

.common-tabs-header {
  display: flex;
  flex-direction: row;
  margin-bottom: -1px;
  .tab {
    border: solid 1px;
    border-bottom: solid 1px;
    padding: 5px;
    cursor: pointer;
    &.selected {
      border-bottom: solid white 1px;
    }
  }
}
.common-tabs-content {
  border: solid 1px;
  padding: 10px;
  display: none;

  &.selected {
    display: block;
  }
}
