.home-page {
  .main {
    padding: 20px;

    .obsolete {
      background: orange;
      font-size: 1.5em;
      padding: 3px;
    }

    .title {
      text-align: left;
      font-weight: bold;
    }

    .set-list {
      margin-bottom: 20px;
    }

    .unity-assets-header {
      text-align: right;
    }

    .unity-assets {
      table {
	border: solid 1px;
	width: 100%;
	margin-bottom: 20px;

	td {
	  padding: 10px;
	  word-break: break-all;

	  ol {
	    text-align: left;
	  }
	}

	input,select {
	  width: 100%;
	}
      }

      .dropzone {
	border: solid 1px;
	padding: 20px;
	cursor: pointer;

	.title {
	  font-weight: bold;
	}
      }
    }
  }

  .theme-settings {
    table {
      width: 100%;
    }
    .dropzone {
      border: solid 1px;
      padding: 20px;
      cursor: pointer;

      .title {
        font-weight: bold;
        text-align: center;
      }
      .format {
        margin-top: 10px;
      }
    }
  }

  .footnote {
    margin-top: 5px;
    font-size: 0.8em;
    text-align: left;
  }
}
